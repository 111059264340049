.loader {
  border: 6px solid #f3f3f3; /* Light grey */
  border-top: 6px solid rgb(44, 44, 111); /* Blue */
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 0.7s linear infinite;
  margin: 10px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}