@import './variables.css';

html {
	background: white;
	background: linear-gradient(to top, var(--top-purple), var(--bottom-purple));
}

body {
	/* margin: 0; */
	background: white;
	font-weight: 500;
	font-style: normal;
	font-family: museo-sans, sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
	  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
	  sans-serif !important;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
  
.cardHeading {
	font-size: 20px;
	font-style: normal;
	font-weight: 900;
	color: white;
	display: flex; 
	justify-content: center;
}

.cardView {
	width: 90%;
	max-width: 500px;
	background-color: white;
	border-radius: var(--card-corner-radius);
	padding: 20px 30px;
	color: black;
	margin-bottom: 20px;
}