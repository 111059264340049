.navbar-custom {
	background-color: clear;
	color: var(--text-color);
	transition: background-color 0.3s;
}

.navbar-custom.scrolled {
	background-color: var(--hover-purple);
	color: var(--text-color);
}

.navbar-custom:hover {
	background-color: var(--hover-purple);
	color: var(--text-hover-color);
}

.navbar-custom .navbar-brand,
.navbar-custom .nav-link {
	color: var(--text-color);
}

.navbar-custom .navbar-brand:hover,
.navbar-custom .nav-link:hover,
.navbar-custom *:visited,
.navbar-custom *:active {
	color: var(--text-hover-color) !important;
}

.navbar-custom .nav-link {
	/* position: relative; */
	font-weight: 700;
}

.navbar-brand img,
.nav-link img
{
	object-fit: contain;
	object-position: center;
}

/* Change the color of the images in Nav.Link on hover */
.navbar-custom .nav-link:hover img {
	filter: brightness(66%);
  }
  
  /* Change the color of the hamburger menu in the collapsed state */
  .navbar-custom .navbar-toggler-icon {
	filter: brightness(0) invert(1);
  }

/* .navbar-custom .nav-link::before {
	content: "";
	position: absolute;
	bottom: 0;
	left: 15%;
	right: 0;
	height: 1px;
	width: 70%;
	background-color: white;
	opacity: 0;
	transition: opacity 0.3s;
} */

/* .navbar-custom .nav-link:hover::before { */
	/* opacity: 1; */
	/* bottom: 3px; */
	/* Add 3px of padding to the top of the underline */
/* } */