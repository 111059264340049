/* input[type="text"]::before { */
	/* content: "";
	position: absolute;
	top: 50%;
	left: 12px;
	transform: translateY(-50%);
	width: 18px;
	height: 18px;
	background-color: red; */
	/* background-image: url("can.png"); */
	/* background-size: contain;
	background-repeat: no-repeat; */
/* } */

/* div { */
	/* background: url(/public/magnifyingglass.svg) no-repeat scroll 7px 7px; */
/* } */

.searchForm {
	display: flex;
	justify-content: center;
}

input {
	border-radius: 12px;
	border: none;
	padding: 10px 20px 10px 20px;
	background-color: rgb(242, 243, 242);
	margin-right: 5px;
}

.searchButton {
	border: none;
	background: rgb(44, 44, 111);
	color: white;
	border-radius: 8px;
	font-size: 10pt;
	font-family: "Montserrat";
	font-weight: bold;
	padding: 10px;
}