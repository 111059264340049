
/* .App {
  text-align: center;
  min-height: 100vh;
} */

/* .App-logo {
  height: 40vmin;
  pointer-events: none;
} */

/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */

/* .App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: top;
  font-size: 30pt;
  color: white;
} */

.appBody {
  background: linear-gradient(to bottom, var(--top-purple), var(--bottom-purple));
  min-height: 1000px;
}

/* .App-link {
  color: #61dafb;
}

.App-Title {
  font-family: 'Montserrat';
  font-weight: 700;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

